
export default {
  name: 'BaseRecommendationsNavigator',
  data() {
    return {
      services: [
        {
          id: 1,
          title: 'Журналы',
          icon: 'navigator-streams',
          url: '/navigator/streams',
        },
        {
          id: 2,
          title: 'Трансляции',
          icon: 'navigator-broadcasts',
          url: '/broadcasts',
        },
        {
          id: 3,
          title: 'Компании',
          icon: 'navigator-companys',
          url: '/navigator/companys',
        },
        {
          id: 4,
          title: 'Обзоры',
          icon: 'navigator-reviews',
          url: '/navigator/products',
        },
        // {
        //   id: 4,
        //   title: 'Акции',
        //   icon: 'navigator-offers',
        //   url: '/marketplace/offers',
        // },
        // {
        //   id: 5,
        //   title: 'BUTTON',
        //   icon: null,
        //   url: '',
        // },
        // {
        //   id: 6,
        //   title: 'Авторы',
        //   icon: 'navigator-authors',
        //   url: '/navigator/authors',
        // },
        // {
        //   id: 8,
        //   title: 'Карьера',
        //   icon: 'navigator-vacancies',
        //   url: '/career',
        // },
        // {
        //   id: 9,
        //   title: 'Продукты',
        //   icon: 'navigator-goods',
        //   url: () => (this.$auth.loggedIn ? '/marketplace/goods' : '/goods'),
        // },
      ],
      isOpenModal: false,
    }
  },
  computed: {
    visibleServices() {
      return this.services.slice(0, 5)
    },
    unVisibleServices() {
      return this.services.slice(5)
    },
  },
  watch: {
    '$route.path'() {
      this.closeModal()
    },
  },
  methods: {
    toggleModal() {
      this.isOpenModal = !this.isOpenModal
    },
    closeModal() {
      this.isOpenModal = false
    },
  },
}
